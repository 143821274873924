window.snippets = window.snippets || {};

/* ============================================================

============================================================ */
window.snippets.lazyload = function(){
  const $lazy = document.querySelectorAll('.js-lazyload');
  const io = new IntersectionObserver(inViewport, {
    threshold: [0]
  });

  Array.from($lazy).forEach(element => {
    io.observe(element);
  });

  function inViewport(entries, observer){
    entries.forEach(entry => {

      if(entry.intersectionRatio > 0){
        const imgEl = entry.target;
        imgEl.src = imgEl.dataset.src;

        imgEl.addEventListener('load', ()=>{
          imgEl.classList.add('is-lazyloaded');
          imgEl.removeAttribute("width");
          imgEl.removeAttribute("height");
        });

        io.unobserve(entry.target);
      }
    });
  }
}

/* ============================================================

============================================================ */

window.snippets.returnFormattedTel = function(tel){
  const validateTelNeo = function (value) {
    return /^[0０]/.test(value) && libphonenumber.isValidNumber(value, 'JP');
  }

  const formatTel = function (value) {
    return new libphonenumber.AsYouType('JP').input(value);
  }

  const validateMobilePhoneNumLength = function (value) {
    const mobilePhonePrefixArray = [
      "090", "080", "070"
    ]
    const mobilePhoneNumLength = 11 + 2;
    let isMobilePhone = false;

    for (let i = 0, len = mobilePhonePrefixArray.length; i < len; i=(i+1)|0) {
      if(value.indexOf(mobilePhonePrefixArray[i]) === 0){
        isMobilePhone = true;
        break;
      }
    }

    if (!isMobilePhone) {
      return true;
    }

    if (value.length === mobilePhoneNumLength) {
      return true;
    }

    return false;
  }

  const main = function (tel) {
    if (!validateTelNeo(tel)) {
      return
    }
    const formattedTel = formatTel(tel);
    /*
    if (!validateMobilePhoneNumLength(formattedTel)) {
      return
    }
    */
    return formattedTel;
  }

  return main(tel);
}

window.snippets.returnPrefectureObjArray = function(){
  const array = [
    { 1: "北海道" },
    { 2: "青森県" },
    { 3: "岩手県" },
    { 4: "宮城県" },
    { 5: "秋田県" },
    { 6: "山形県" },
    { 7: "福島県" },
    { 12: "茨城県" },
    { 13: "栃木県" },
    { 14: "群馬県" },
    { 10: "埼玉県" },
    { 11: "千葉県" },
    { 8: "東京都" },
    { 9: "神奈川県" },
    { 16: "新潟県" },
    { 18: "富山県" },
    { 19: "石川県" },
    { 20: "福井県" },
    { 15: "山梨県" },
    { 17: "長野県" },
    { 22: "岐阜県" },
    { 23: "静岡県" },
    { 21: "愛知県" },
    { 24: "三重県" },
    { 28: "滋賀県" },
    { 27: "京都府" },
    { 25: "大阪府" },
    { 26: "兵庫県" },
    { 29: "奈良県" },
    { 30: "和歌山県" },
    { 31: "鳥取県" },
    { 32: "島根県" },
    { 33: "岡山県" },
    { 34: "広島県" },
    { 35: "山口県" },
    { 36: "徳島県" },
    { 37: "香川県" },
    { 38: "愛媛県" },
    { 39: "高知県" },
    { 40: "福岡県" },
    { 41: "佐賀県" },
    { 42: "長崎県" },
    { 43: "熊本県" },
    { 44: "大分県" },
    { 45: "宮崎県" },
    { 46: "鹿児島県" },
    { 47: "沖縄県" }
  ]
  return array;
}

/* ============================================================
カタカナをひらがなに変換
============================================================ */
window.snippets.katakanaToHiragana = function(str) {
  return str.replace(/[\u30a1-\u30f6]/g, function(match) {
    const char = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(char);
  });
}

/* ============================================================
全角英数字を半角英数字に変換
============================================================ */
window.snippets.zenkakuToHankaku = function(str) {
  return str.replace(/[！-～]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

/* ==========================================
*  ランダム数字生成
========================================== */
window.snippets.getRandomInt = function(min, max) {
  return Math.floor(Math.random() * (max + 1 - min)) + min;
}

/* ============================================================
URL parameters
============================================================ */

//パラメータを設定したURLを返す
window.snippets.setParameter = function(paramsArray) {
  var resurl = location.href.replace(/\?.*$/, "");
  for (key in paramsArray) {
    resurl += (resurl.indexOf('?') == -1) ? '?' : '&';
    resurl += key + '=' + paramsArray[key];
  }
  return resurl;
}

//パラメータを取得する
window.snippets.getParameter = function() {
  let paramsArray = [];
  let url = location.href;
  parameters = url.split("#");
  if (parameters.length > 1) {
    url = parameters[0];
  }
  parameters = url.split("?");
  if (parameters.length > 1) {
    let params = parameters[1].split("&");
    for (i = 0; i < params.length; i++) {
      const paramItem = params[i].split("=");
      paramsArray[paramItem[0]] = paramItem[1];
    }
  }
  return paramsArray;
}

/* ==========================================
*  keyを指定して特定のvalueを持つオブジェクトを取得
========================================== */

window.snippets.getObjByKeyValue = function(array, key, value) {
  const result = $.grep(array, function(e) {
    return e[key] == value;
  });
  return result;
}

/* ==========================================
*  オブジェクト配列ソート
*  array   : Array [{},{},{}...]
*  key   : string
*  order : string "asc"(default) or "desc"
========================================== */

window.snippets.sortObjArray = function(array, key, order) {
  let reverse = 1;
  if (order && order.toLowerCase() == "desc") {
    reverse = -1;
  }
  array.sort(function(a, b) {
    if (a[key] < b[key]) {
      return -1 * reverse;
    } else if (a[key] == b[key]) {
      return 0;
    } else {
      return 1 * reverse;
    }
  });
}

/* ==========================================
* Cookieの設定
* Cookie名, Cookieの値, Cookieの有効日数
========================================== */

window.snippets.setCookie = function(c_name, value, expiredays) {
  let path = location.pathname;
  let paths = new Array();
  paths = path.split("/");
  if (paths[paths.length - 1] != "") {
    paths[paths.length - 1] = "";
    path = paths.join("/");
  }
  const extime = new Date().getTime();
  const cltime = new Date(extime + (60 * 60 * 24 * 1000 * expiredays));
  const exdate = cltime.toUTCString();
  let s = "";
  s += c_name + "=" + escape(value);
  s += "; path=" + path;
  if (expiredays) {
    s += "; expires=" + exdate + "; ";
  } else {
    s += "; ";
  }
  document.cookie = s;
}

/* ==========================================
* Cookieの取得
* Cookie名, Cookieの値, Cookieの有効日数
========================================== */

window.snippets.getCookie = function(c_name) {
  let st = "";
  let ed = "";
  if (document.cookie.length > 0) {
    st = document.cookie.indexOf(c_name + "=");
    if (st != -1) {
      st = st + c_name.length + 1;
      ed = document.cookie.indexOf(";", st);
      if (ed == -1) ed = document.cookie.length;
      return unescape(document.cookie.substring(st, ed));
    }
  }
  return "";
}

/* ==========================================
*  ユーザーエージェント
========================================== */

window.snippets.addUserAgent = function() {
  window.UA = {};

  var $body = $("body"),
    ua = navigator.userAgent.toLowerCase();

  UA.os = (function() {
    return {
      Windows: (ua.indexOf("win") != -1),
      Mac: ((ua.indexOf('mac') > -1) && (ua.indexOf('os') > -1)) && !((ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1) || (ua.indexOf('windows') > -1)),
      iOS: (ua.indexOf('iphone') > 0) || (ua.indexOf('ipad') > 0),
      Android: (ua.indexOf('android') > 0) && (ua.indexOf('mobile') > 0)
    }
  })();

  UA.browser = (function() {
    return {
      IE: (ua.indexOf('msie') > -1) && (ua.indexOf('opera') == -1) || (ua.indexOf('trident/7') > -1),
      Edge: (ua.indexOf('edg') > -1),
      Chrome: (ua.indexOf('chrome') > -1) && (ua.indexOf('edg') == -1),
      Firefox: (ua.indexOf('firefox') > -1),
      Safari: (ua.indexOf('safari') > -1) && (ua.indexOf('chrome') == -1),
      Opera: (ua.indexOf('opera') > -1)
    }
  })();

  UA.device = (function() {
    return {
      Tablet: (ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) ||
        ua.indexOf("ipad") != -1 ||
        (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1) ||
        (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1) ||
        ua.indexOf("kindle") != -1 ||
        ua.indexOf("silk") != -1 ||
        ua.indexOf("playbook") != -1,
      Mobile: (ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1) ||
        ua.indexOf("iphone") != -1 ||
        ua.indexOf("ipod") != -1 ||
        (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1) ||
        (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1) ||
        ua.indexOf("blackberry") != -1
    }
  })();

  UA.device.PC = (function() {
    var pc;
    if (UA.device.Tablet || UA.device.Mobile) {
      var pc = false;
    } else {
      var pc = true;
    }
    return pc;
  })();

  if (UA.browser.Opera) {
    $body.attr("data-browser", "Opera");
  } else if (UA.browser.IE) {
    $body.attr("data-browser", "IE");
  } else if (UA.browser.Chrome) {
    $body.attr("data-browser", "Chrome");
  } else if (UA.browser.Safari) {
    $body.attr("data-browser", "Safari");
  } else if (UA.browser.Edge) {
    $body.attr("data-browser", "Edge");
  } else if (UA.browser.Firefox) {
    $body.attr("data-browser", "Firefox");
  }

  if (UA.device.Mobile) {
    $body.attr("data-device", "Mobile");
  } else if (UA.device.Tablet) {
    $body.attr("data-device", "Tablet");
  } else {
    $body.attr("data-device", "PC");
  }

  if (UA.os.iOS) {
    $body.attr("data-os", 'iOS');
  } else if (UA.os.Android) {
    $body.attr("data-os", 'Android');
  } else if (UA.os.Windows) {
    $body.attr("data-os", 'Windows');
  } else if (UA.os.Mac) {
    $body.attr("data-os", 'Mac');
  }

}

/* ==========================================
*  スムーススクロール
========================================== */

window.snippets.smoothScroll = function(offsetY, time, easing, scrollLink) {
  if (typeof offsetY === "undefined") {
    var offsetY = -50;
  }
  if (typeof time === "undefined") {
    var time = 1000;
  }
  if (typeof easing === "undefined") {
    var easing = "ease";
  }
  if (typeof scrollLink === "undefined") {
    var scrollLink = 'a[href^="#"],a[href^="index.php#"]';
  }

  var scrollLink = $(scrollLink),
    scrollToTop = $('.js-scrollToTop');

  scrollLink.on("click", function() {
    var target = $(this.hash);
    if (!target.length) return;
    var targetY = target.offset().top + offsetY;
    $('html,body').animate({
      scrollTop: targetY
    }, time, easing);
    window.history.pushState(null, null, this.hash);
    return false;
  });

  scrollToTop.on("click", function() {
    $('html,body').animate({
      scrollTop: 0
    }, time, easing);
    return false;
  })
}

/* ==========================================
*  ソーシャルシェア
========================================== */

window.snippets.socialShare = function(hashtag, related) {

  if (typeof hashtag === "undefined") {
    var hashtag = "";
  }
  if (typeof related === "undefined") {
    var related = "";
  }

  var title = encodeURI(decodeURI(document.title)).replace(/&/g, "%26").replace(/#/g, "%23"),
    encodeHashtag = encodeURI(decodeURI(hashtag)).replace(/#/g, ""),
    url = location.href.split("#"),
    $shareTwitter = $('.js-share--twitter'),
    $shareFacebook = $('.js-share--facebook'),
    $shareLine = $('.js-share--line'),
    twitterHref = 'https://twitter.com/intent/tweet?text=' + title + '%0D%0A' + '&url=' + url[0] + '&hashtags=' + encodeHashtag + '&related=' + related,
    facebookHref = 'http://www.facebook.com/sharer.php?u=' + url[0],
    lineHref = 'https://social-plugins.line.me/lineit/share?url=' + url[0] + '&text=' + title;

  function newShareWindow() {
    var w = 550,
      h = 500,
      x = (screen.width - w) / 2,
      y = (screen.height - h) / 2;
    window.open(this.href, null, 'screenX=' + x + ',screenY=' + y + ',left=' + x + ',top=' + y + ',width=' + w + ',height=' + h);
    return false;
  }

  $shareTwitter.attr('href', twitterHref);
  $shareFacebook.attr('href', facebookHref);
  $shareLine.attr('href', lineHref);

  $shareTwitter.on('click', newShareWindow);
  $shareFacebook.on('click', newShareWindow);
  $shareLine.on('click', newShareWindow);
}
